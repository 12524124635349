#primary-header {
    border-bottom: 1px solid #CCC;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    height: 91px;
    justify-content: space-between;
    position: relative;

    &-menu {
        border-right: 1px solid #CCC;
        flex: 0 0 172px;
    }

    &-logo {
        height: 70%;
        left: 50%;
        margin-left: -53px;
        position: absolute;
        top: 15%;
        width: 106px;

        img {
            max-width: 100%;
        }
    }

    &-search {
        align-items: stretch;
        border-left: 1px solid #CCC;
        flex: 0 0 90px;
        justify-content: stretch;

        a {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
        }
    }
}
#rbs-primary-header {
    border-bottom: 1px solid #CCC;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    height: 86px;
    justify-content: space-between;
    position: relative;

    &-menu {
        flex: 0 0 172px;
    }

    &-logo {
        height: 70%;
        left:49%;
        margin-left: -53px;
        position: absolute;
        top: 15%;
        width: 106px;

        img {
            max-width: 130%;
        }
    }

    &-search {
        align-items: stretch;
        flex: 0 0 90px;
        justify-content: stretch;

        a {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
        }
    }
}
.search-sync-container{
    display:flex;
    justify-content: center;
    align-items: center;

    .sync-btn{
        border:1px solid $cumin;
        color:$cumin;
        font-family:$font-helvetica;
        width:196px;
        

        &-icon{
            padding-right:10px;
            color:$cumin;
        }
    }
}