/**
 * Bootstrap Overrides
 */

.btn-primary {
    color: $white;
    font-size: 16px;
    padding: 11px 18px;

    &--amf{
        background-color: $mine-shaft;
        font-family: "montserrat-bold", sans-serif;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            background-color: lighten($mine-shaft, 10%);
        }
    }
    &--rbs{
        background-color: $cumin;
        font-family: $font-josefin;
        text-transform: none;
        border-radius: 0%;

        &:focus{
            outline: none!important;
            border: 1px solid $cumin;
            box-shadow: 0 0 10px;
        }
        &:not(:disabled):not(.disabled):active:focus{
            box-shadow:none;
        }

        &:hover{ 
            background-color: lighten($cumin, 10%);
        }
        &:not(:disabled):not(.disabled):active{
            background-color:$cumin!important;
        }
    }

}
a.btn-primary.btn-primary--rbs.assets-button.assets-button--rbs{
    background-color:$cumin;
  }
.assets-button{

    &--rbs{
        font-family: $font-helvetica;
    }
}
.asset-caret-right{
    margin-bottom:3px;
}
.secondary-link-caret-right {
    margin-bottom: 4px;
    padding-left: 7px;
}

.breadcrumb {
    padding: 0.75rem 3rem;
}

/**
 * Layout
 */
.inner-width {
    margin: 0 auto;
    max-width: 1500px;
    padding: 0 30px;

    &--amf{

    }
    &--rbs{
        
        p{
            color:$metallic-bronze;
            font-size: 16px;
            font-family: $font-helvetica;
        }
    }
}
.outer-width-rbs{
    width: 100%;
    display: flex;
    justify-content: center;
}
.inner-width-rbs{
    width: 1500px;
    margin: 0 4.5%;

    p{
        color:$metallic-bronze;
        font-family: $font-helvetica;
    }

}
.inner-width2 {
    margin: 0 auto;
    max-width: 1500px;
    padding: 0 30px;
    margin-top:200px;
}
.login-container{
    height:100%;
    width: 100%;
    display:flex;
    align-items:center;
    justify-content:center;

  }
  .login-row{
    margin-top:50px;
    width: 100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .login-box{
      width:500px;
      margin-left:130px;
  }
 


/**
 * Header
 */

h1 {
    font-family: $font-saira-bold;
    font-size: 40px;
    // font-weight: bold;
    text-transform: uppercase;
}

h2 {
    color: $gray;
    font-family: $font-montserrat-bold;
    font-size: 18px;
    // font-weight: bold;
    text-transform: uppercase;
}
p{
    font-family: $font-merriweather;
}
.w-100{
    margin-top:200px;
}
.loading-text{

    &--amf{
        margin-top:16px;
    }
    &--rbs{
        color:$metallic-bronze;
        font-family:$font-lato;
        font-weight: $bold;
        font-size:28px;
        margin-top:31px;
    }
}
.progress{
    margin-top:20px;
}
.progress-bar{

    &--amf{

    }
    &--rbs{
        background-color:$cumin;
    }
}