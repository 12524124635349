.product {
    &-image {
        margin-bottom: 26px;
        padding: 30px 10px;
        position: relative;

        &-img {
            max-width: 100%;
        }

        &-links {
            bottom: 0;
            display: flex;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;

            &-link {
                border: 1px solid $alizarin-crimson;
                color: $alizarin-crimson;
                display: flex;
                font-weight: bold;
                height: 100%;
                justify-content: center;
                margin-right: 10px;

                &:hover {
                    color: $alizarin-crimson;
                }

                &:nth-child(even) {
                    align-items: flex-start;
                }

                &:nth-child(odd) {
                    align-items: flex-end;
                }

                &:last-child {
                    margin-right: 0;
                }

                .product-594 & {
                    &:nth-child(1) {
                        flex-basis: 25%;
                    }

                    &:nth-child(2) {
                        flex-basis: 43%;
                    }

                    &:nth-child(3) {
                        flex-basis: 31%
                    }
                }

                .product-595 & {
                    &:nth-child(1) {
                        flex-basis: 26%;
                    }

                    &:nth-child(2) {
                        flex-basis: 37%;
                    }

                    &:nth-child(3) {
                        flex-basis: 37%;
                    }
                }

                .product-598 & {
                    &:nth-child(1) {
                        flex-basis: 17%;
                    }

                    &:nth-child(2) {
                        flex-basis: 44%;
                    }

                    &:nth-child(3) {
                        flex-basis: 39%;
                    }
                }

                .product-600 & {
                    flex-basis: 28%;

                    &:nth-child(1) {
                        flex-basis: 47%;
                    }
                }

                .product-601 & {
                    &:nth-child(1) {
                        flex-basis: 18%;
                    }

                    &:nth-child(2) {
                        flex-basis: 47%;
                    }

                    &:nth-child(3) {
                        flex-basis: 34%;
                    }
                }

                .product-602 & {
                    &:nth-child(1) {
                        flex-basis: 29%;
                    }

                    &:nth-child(2) {
                        flex-basis: 71%;
                    }
                }

                .product-606 & {
                    &:nth-child(1) {
                        flex-basis: 36%;
                    }

                    &:nth-child(2) {
                        flex-basis: 22%;
                    }

                    &:nth-child(3) {
                        flex-basis: 42%;
                    }
                }

                .product-607 & {
                    &:nth-child(1) {
                        flex-basis: 39%;
                    }

                    &:nth-child(2) {
                        flex-basis: 27%;
                    }

                    &:nth-child(3) {
                        flex-basis: 33%;
                    }
                }

                .product-608 & {
                    &:nth-child(1) {
                        flex-basis: 45%;
                    }

                    &:nth-child(2) {
                        flex-basis: 55%;
                    }
                }

                .product-609 & {
                    &:nth-child(1) {
                        flex-basis: 13%;
                    }

                    &:nth-child(2) {
                        flex-basis: 55%;
                    }

                    &:nth-child(3) {
                        flex-basis: 31%;
                    }
                }

                .product-610 & {
                    flex-basis: 53%;

                    &:nth-child(1) {
                        flex-basis: 47%;
                    }
                }

                .product-611 & {
                    &:nth-child(1) {
                        flex-basis: 34%;
                    }

                    &:nth-child(2) {
                        flex-basis: 66%;
                    }
                }

                .product-612 & {
                    &:nth-child(1) {
                        flex-basis: 42%;
                    }

                    &:nth-child(2) {
                        flex-basis: 31%;
                    }

                    &:nth-child(3) {
                        flex-basis: 26%;
                    }
                }

                .product-975 & {
                    flex-basis: 70.5%;

                    &:nth-child(1) {
                        flex-basis: 29.5%;
                    }
                }
            }
        }
    }

    &-accent-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 129px;
        margin-bottom: -51px;
    }
}