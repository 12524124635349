@if $target == 'web' {

/**
 * Montserrat
 */
@font-face {
    font-family: 'montserrat-bold';
    font-style: normal;
    font-weight: normal;
    src: url('/webfonts/montserrat-bold-webfont.woff2') format('woff2'),
        url('/webfonts/montserrat-bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'montserrat-regular';
    font-style: normal;
    font-weight: normal;
    src: url('/webfonts/montserrat-regular-webfont.woff2') format('woff2'),
        url('/webfonts/montserrat-regular-webfont.woff') format('woff');
}

/**
 * Merriweather
 */
@font-face {
    font-family: 'merriweather-bold';
    font-style: normal;
    font-weight: normal;
    src: url('/webfonts/merriweather-bold-webfont.woff2') format('woff2'),
        url('/webfonts/merriweather-bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'merriweather-regular';
    font-style: normal;
    font-weight: normal;
    src: url('/webfonts/merriweather-regular-webfont.woff2') format('woff2'),
        url('/webfonts/merriweather-regular-webfont.woff') format('woff');
}

/**
 * Saira Condensed
 */

@font-face {
    font-family: 'saira-condensed-bold';
    font-style: normal;
    font-weight: normal;
    src: url('/webfonts/sairacondensed-bold-webfont.woff2') format('woff2'),
        url('/webfonts/sairacondensed-bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'saira-condensed-regular';
    font-style: normal;
    font-weight: normal;
    src: url('/webfonts/sairacondensed-regular-webfont.woff2') format('woff2'),
        url('/webfonts/sairacondensed-regular-webfont.woff') format('woff');
}
/**
 * Lato
 */
 @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /**
 * Josefin Sans
 */
 @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Josefin Sans Light'), local('JosefinSans-Light'), url(https://fonts.gstatic.com/s/josefinsans/v14/Qw3FZQNVED7rKGKxtqIqX5Ecpl5te10hoJky_A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Josefin Sans Regular'), local('JosefinSans-Regular'), url(https://fonts.gstatic.com/s/josefinsans/v14/Qw3aZQNVED7rKGKxtqIqX5EUDXx4Vn8sig.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Josefin Sans SemiBold'), local('JosefinSans-SemiBold'), url(https://fonts.gstatic.com/s/josefinsans/v14/Qw3FZQNVED7rKGKxtqIqX5Ec0lhte10hoJky_A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Josefin Sans Bold'), local('JosefinSans-Bold'), url(https://fonts.gstatic.com/s/josefinsans/v14/Qw3FZQNVED7rKGKxtqIqX5Ectllte10hoJky_A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
           
}
