.download-row-container{
    padding: .4rem 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.download-box{
    margin: -6px 15px 15px 0;

    &--rbs{
        color:$white;

        &:hover{
            color:$white;
        }
    }
}
.download-product-row-item{
    flex: 0 0 13.75rem;
    height: 13.75rem;
    width: 13.75rem;
    margin: 54px 15px 15px 0;
    display: flex;
}
.download-product-row-item-content{
    background-color:$cumin;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.download-product-row-item-content-inner{
    border: 1px solid white;
    height: 88%;
    width: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 0 8px;
}
.download-product-row-item-content-inner-margin{
    
    h5{
        font-family:$font-josefin;
        color:$white;
        font-size:24px;
        text-align:center;
        font-weight:$bold;
        text-transform:uppercase;
    }

}