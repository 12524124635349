// COLORS
$cumin:                 #95421F;
$bridal-heath:          #FFFAF2;
$metallic-bronze:       #4D361E;
$alizarin-crimson:      #E31B23;
$mine-shaft:            #333;
$white:                 #fff;
$gray:                  #7F7F7F;
$alto:                  #D8D8D8;
$silver:                #CCC;
$concrete:              #F2F2F2;
$black:                 #000;
$body-bg:               #fff;
$body-color:            #000;
$link-color:            #000;

$link-decoration: none;
$link-hover-color: #fff;
$link-hover-decoration: underline;

// GRID VARIABLES
// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 30px;


//Padding
$pad-top: 4em;
$foot-top: 5em;


//THUMBNAILS
$thb-height: 16.4em;


// FONTS
$font-montserrat-bold:          'montserrat-bold', sans-serif;
$font-montserrat:               'montserrat-regular', sans-serif;

$font-merriweather-bold:        'merriweather-bold', serif;
$font-merriweather:             'merriweather-regular', serif;

$font-saira-bold:               'saira-condensed-bold', serif;
$font-saira:                    'saira-condensed-regular', serif;

$font-josefin:                  'Josefin Sans', sans-serif;

$font-lato:                     'Lato', sans-serif;

$font-helvetica:                "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;  

//FONT WEIGHTS
$weight-black: 900;
$bold: 700;
$regular: 400;
$light: 300;
$semibold: 600;