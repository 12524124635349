.asset-gallery {
    align-items: center;
    display: flex;
    justify-content: center;

    &-items {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        &-item {
            margin-bottom: 46px;

            &--amf{

            }
            &--rbs{
                height:544px;
            }

            &-inner {
                align-items: center;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                justify-content: center;
                position: relative;
                width: 100%;

                &--amf {
                    height: 100%;
                }

                &--rbs {
                    height: 96%;
                }
            }

            &-content {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: space-between;
                left: 0;
                position: absolute;
                right: 0;

                &--amf{
                    background-color: $mine-shaft;
                    font-family: $font-merriweather;
                    color: $white;
                    height: 46px;
                    font-size: 13.5px;
                }
                &--rbs{
                    background-color: $bridal-heath;
                    font-family: $font-josefin;
                    color: $metallic-bronze;
                    height: 74px;
                    font-size: 18px;
                }

                a.download-link {

                    &--amf{
                        color: $white;
                    }
                    &--rbs{
                        color:$cumin;
                        background-color:$white;
                        border: 1px solid $cumin;
                        padding-right:10px;
                        font-family:$font-helvetica;
                        
                        &:nth-child(2) {
                            color:$cumin;
                            font-family:$font-helvetica;
                        }
                    }
                    img {
                        display: inline-block;
                        margin-left: 10px;
                        width: auto;
                    }
                }

                >div {
                    &:nth-child(1) {
                        padding-left: 14px;
                        text-align: left;
                    }

                    &:nth-child(2) {
                        display: flex;
                        text-align: right;
                        a {
                            align-items: center;
                            display: inline-flex;
                            margin-right: 14px;

                            &--amf{
                                color: $white;
                            }
                            &--rbs{
                                color: $metallic-bronze;
                            }
                        }
                    }
                }
            }

            &-file-extension {

                box-sizing: border-box;	
                height: 25px;	
                width: 60px;	
                border-radius: 16.5px;	
                font-family: $font-helvetica;
                text-align: center;
                text-transform: uppercase;
                margin-left: 15px;
                font-size: 14px;
                padding-top: 2px;

                &--rbs {
                    background-color:$white;
                    color:$metallic-bronze;
                    border: 1px solid $metallic-bronze;	
                }
            }
            &-file-title-conatiner{
                flex: 1 0 30%;
                display: flex;
                align-items: center;
                height: 100%;
            }

            img {
                width: 100%;
            }
        }

        /**
         * Carousel Overrides
         */
        .carousel.carousel-slider {
            overflow: visible;

            .slide {
                background-color: $concrete;
                position: relative;

                img {
                    width: auto;
                }
            }

            .control-arrow {
                color: $metallic-bronze;
                font-size: 50px;
                padding: 0;
                position: absolute;
                top: 0;

                &:before {
                    border: 0 none;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    margin: 0;
                }

                &:hover {
                    background: none;
                }

                &.control-next {
                    right: -50px;

                    &:before {
                        display: block;
                        content: ' ';
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='41px' viewBox='0 0 24 41' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3Edown carat copy%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='View-Asset---Kiosk' transform='translate(-1296.000000, -560.000000)' fill='%234D361E' fill-rule='nonzero'%3E%3Cpath d='M1308.13716,592 L1308.13716,592 C1307.72516,591.999617 1307.33016,591.826588 1307.03901,591.518953 L1288.49464,571.839769 C1288.07889,571.430943 1287.90775,570.815236 1288.04837,570.23419 C1288.18898,569.653144 1288.61892,569.19943 1289.16952,569.051041 C1289.72011,568.902652 1290.30356,569.083254 1290.69096,569.521998 L1308.24076,588.020432 L1325.89417,569.390803 C1326.51786,568.994349 1327.31878,569.108034 1327.82104,569.664311 C1328.3233,570.220589 1328.39224,571.070297 1327.98688,571.708574 L1309.23532,591.497088 C1308.9476,591.812758 1308.55217,591.993848 1308.13716,592 Z' id='down-carat-copy' transform='translate(1308.125000, 580.500000) scale(-1, 1) rotate(90.000000) translate(-1308.125000, -580.500000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        background-size: 52px 40px;
                        height: 40px;
                        width: 37px;
                    }
                }

                &.control-prev {
                    left: -50px;

                    &:before {
                        display: block;
                        content: ' ';
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='41px' viewBox='0 0 24 41' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3Edown carat%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='View-Asset---Kiosk' transform='translate(-120.000000, -560.000000)' fill='%234D361E' fill-rule='nonzero'%3E%3Cpath d='M132.137163,592 L132.137163,592 C131.725157,591.999617 131.330157,591.826588 131.039006,591.518953 L112.494644,571.839769 C112.078892,571.430943 111.907753,570.815236 112.048366,570.23419 C112.18898,569.653144 112.618918,569.19943 113.169516,569.051041 C113.720115,568.902652 114.303558,569.083254 114.69096,569.521998 L132.240763,588.020432 L149.894167,569.390803 C150.517862,568.994349 151.318777,569.108034 151.82104,569.664311 C152.323303,570.220589 152.392236,571.070297 151.986882,571.708574 L133.235321,591.497088 C132.947597,591.812758 132.552169,591.993848 132.137163,592 Z' id='down-carat' transform='translate(132.125000, 580.500000) rotate(90.000000) translate(-132.125000, -580.500000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                        background-size: 26px 40px;
                        height: 40px;
                        width: 26px;
                    }
                }
            }
        }

        /**
         * PDF Overrides
         */
        .react-pdf__message--loading {
            width: 184px;
            margin: 0 auto;
        }

        /**
         * Video Overrides
         */
        .rh5v-DefaultPlayer_controls {
            opacity: 1;
        }

        .rh5v-Volume_icon,
        .rh5v-Fullscreen_icon,
        .rh5v-PlayPause_icon {
            padding: 0;
        }
    }

    &-container {
        padding: 40px;
    }

    .gallery-icon {
        height: 29px;
        margin-top: 6px;
        margin-bottom: 5px;

        &--rbs{
            padding-right:10px;
        }
    }

    .rbs-mail-icon{
        padding-right: 10px;
    }
}
