.error {
    align-items: center;
    display: flex;
    margin: 51px auto 0;
    max-width: 900px;
    width: 100%;

    .fas {
        font-size: 36px;
        margin-right: 20px;
    }

    p {
        margin: 0;
    }
}
.warning-icon{
    width:36px;
}