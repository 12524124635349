.product-detail {
    &-full-system-link {
        border-bottom: 3px solid $silver;
        border-top: 3px solid $silver;
        display: inline-block;
        font-family: $font-montserrat-bold;
        font-size: 16px;
        padding: 16px 7px;

        .fa {
            font-size: 18px;
            margin-right: 4px;

            &-caret-left {
                color: #E31B23;
            }
        }
    }

    &-image {
        margin: 0 auto;

        &--amf{
            padding: 30px 0;
        }
        &--rbs{
            padding: 10px 0;
        }
    }

    &-accent-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 129px;
        margin-bottom: -51px;
    }
    .product-detail-loader{
        margin-top:-170px;
    }
}
.rbs-product-detail-accent-image-container{
    width:100%;
    display:flex;
    justify-content: center;
    margin-bottom:2%;
    
}