.footer {
    &-menu {
        &-col {
            background-color: $concrete;
        }

        &-items {
            margin-top: 1.4em;

            &--amf {
                h6 {
                    padding-bottom: 1.5em;
                }
            }
        }

        &-main {
            &-row {
                margin-top: $foot-top;
            }

            &-title:after {
                content: "";
                display: block;
                width: 85%;
                padding-top: 1px;
                border-bottom: 1px solid #E85B61;
            }
        }

        &-nested-row {
            padding-left: 1.5em;

            &--amf{
                margin-top: 1.5em;
            }
            &--rbs{
                height:130px;
                width:100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &-link {
        margin-top: 1em;
    }

    &-logo {
        width: 130pt;
        height: 80pt;
        margin-top: 5em;
    }
    &-version {
        text-align: center;
        margin-top: -1.5em;
        color: $gray;
    }
}

.footer-column-header {
    font-size: 10px;
    margin-bottom: 5.5px;
    
}
.service-footer-column-header{
    font-size: 14px;
    margin-bottom: 5.5px;
}
.corporate-footer-column-header{
    font-size: 14px;
    margin-bottom: 5.5px;
}

.footer-ul {
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        float: left;
    }

    &--amf{

        li:not(:last-child) {
            border-right: 1px solid black;
            margin-right: 4px;
            padding-right: 4px;
        }
    }

    &--rbs{
        li:not(:last-child) {
            border-right: 1px solid $metallic-bronze;
            margin-right: 9px;
            padding-right: 9px;
        }
    }
}

.version-number-text{
    font-family:$font-helvetica;
    font-size:14px;
    color:#9B9B9B;
    text-align: center;

    a{
        font-family:$font-helvetica;
        font-size:14px;
        color:#9B9B9B;
        text-align: center;

        &:hover{
            font-family:$font-helvetica;
            font-size:14px;
            color:#9B9B9B;
            text-align: center;
            text-decoration: none;
        }
    }
}
.version-download-column{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right: 4.25rem;

    &-corporate{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        padding:0;
    }
}
.service-corporate-container{
    flex: 1 0 50%;
    display: flex;
    margin-left: 2.5rem;
    justify-content: flex-start;
    max-width:745px;
}
.service-row{
    display:flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 64%;
}
.corporate-column{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    padding:0;
}
.download-btn-row{

    a{
        color:transparent;

        &:hover{
            color: transparent;
        }

    }

}