.unit-search-page-title-container{
    width:100%;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    margin-left:25px;
}
.unit-search-page-title-row{
    width:100%;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    button {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: 15px;
    }

    &--amf{

    }
    &--rbs{
        margin-left: 4.8%;
    }
}
.backbtn{
    &--amf{

    }
    &--rbs{
        margin-left: 5%;
    }
}

.unit-search-page-title {
    line-height: 48px;
    padding-right:18px;

    &--amf{
        font-family: $font-saira-bold;
        font-size: 40px;
    }
    &--rbs{
        font-family: $font-lato; 
        font-weight: $weight-black;
        font-size: 30px;
        color:$metallic-bronze;
        text-transform:none;
        
    }
}

.unit-search-results {
    color: #9B9B9B;
    font-family: $font-montserrat;
    font-size: 16px;
    // font-weight: 500;
    line-height: 19px;
    padding-top:20px;
}

.unit-equipment-search-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &--amf{
        justify-content: flex-start;
    }
    &--rbs{
        justify-content: flex-end;
        margin-left: 5%;
    }
}

.unit-search-column {
    align-items: flex-start;
    height: 100%;

    &--amf{
        flex: 0 0 29%;
    }
    &--rbs{
        flex: 0 0 19%;
    }
}

.unit-equipment-column {
    height: 1255px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &--amf{
        background-color: #E5E5E5;
        flex: 0 0 71%;
    }
    &--rbs{
        background-color: $white;
        flex: 0 0 81%;
    }
}

.unit-equipment-row1 {

    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 19px;

    &--amf{
        padding-left:80px;
    }
    &--rbs{

    }
}

.unit-equipment-box {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    height: 210.12px;
    width: 200px;
    margin: 9px 9px 9px 9px;
    justify-content: center;
    align-items: center;
}

.unit-equipment-next-button-row {
    margin-top: 5px;
    margin-right: 18px;
    margin-bottom: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.unit-equipment-next-button-row2 {
    margin-top: 13px;
}

.unit-equipment-next-button-text {
    font-size: 16px;
    line-height: 19px;

    &--amf{
        color: #000000;
        font-family: $font-montserrat;
    }
    &--rbs{
        color: $metallic-bronze;
        font-family: $font-helvetica;
    }

}

.unit-equipment-next-button-button {
    height: 44px;
    width: 44px;
    margin-left: 11px;
    margin-right: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--amf{
        background-color: #333333;
    }

    &--rbs{
        background-color: $cumin;
    }
}

.unit-equipment-next-button-icon {
    color: white !important;
}


.unit-search-column-limiter {
    width: 90%;
    margin-top: 3px;
    margin-left: 10px;
}

.unit-search-title {
    line-height: 29px;

    &--amf{
        font-family: $font-saira;
        font-size: 24px;
        color: #4C4C4C;
        border-bottom: 1px solid #CCCCCC;
    }
    &--rbs{
        font-family: $font-josefin;
        font-weight:$regular;
        font-size: 18px;
        color: $metallic-bronze;
        border-bottom: 1px solid $metallic-bronze;
        text-transform: uppercase;

        h3{
            font-family: $font-josefin;
            font-weight:$regular;
            font-size: 18px;
        }
    }
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: gray;
}

/* The checkbox container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-top: 15px;
    margin-bottom: 30px;
    cursor: pointer;
    line-height: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--amf{
        font-family: $font-merriweather;
        font-size: 16px;
    }
    &--rbs{
        font-family:$font-helvetica;
        font-size:16px;
        color:$metallic-bronze;

        input:checked~.checkmark{
            background-color: $cumin;
        }
    }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    background-color: #fff;
    margin-right:10px;

    &--amf{
        border: 1px solid #B8B8B8;
    }
    &--rbs{
        border: 1px solid $cumin;
    }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 12px;
    top: 6px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.second-page-pagination-row {
    margin-top: 10px;
    margin-right: 36px;
    margin-bottom: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.second-page-pagination-text-row {
    margin-top: 13px;
}

.second-page-pagination-text {
    color: #BD10E0;
    font-family: Arial;
    font-size: 18px;
    line-height: 21px;
}

.second-page-pagination-button1 {
    height: 44px;
    width: 44px;
    margin-left: 11px;
    margin-right: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--amf{
        background-color: #333333;
    }
    &--rbs{
        background-color:$cumin;
    }
}

.second-page-pagination-button1-icon {
    color: white !important;
}

.second-page-pagination-page-2-row {
    margin-top: 13px;
}

.second-page-pagination-page-2 {
    color: #000000;
    font-family: $font-montserrat;
    font-size: 16px;
    line-height: 19px;
}

.second-page-pagination-button2 {
    height: 44px;
    width: 44px;
    margin-left: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--amf{
        background-color: #333333;
    }
    &--rbs{
        background-color:$cumin;
    }
}

.third-page-pagination-double-arrow {
    height: 44px;
    width: 44px;
    margin-left: 11px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--amf{
        background-color: #333333;
    }
    &--rbs{
        background-color: $cumin;
    }
}

.last-page-pagination-blank {
    height: 44px;
    width: 44px;
    margin-left: 11px;
    margin-right: 17px;
    background-color: transparent;

}
.pagination-right-icon{
    width: 30px;
    height: 27px;
}