
.asset-drawer{
    border-radius:0;
    margin-top:19px;
    font-size:16px;

    &--amf{
      background-color: $concrete ;
      border-right:1px solid #D8D8D8!important;
      font-family:$font-montserrat;
      text-transform: uppercase;
      width:183px;
      height: 66px;
    }
    &--rbs{
      background-color: $white ;
      font-family:$font-josefin;
      text-transform: none;
      color:$metallic-bronze;
      border-bottom: 1px solid $cumin!important;
      border-radius:none;
      width:240px;
      height:50px;
    }
}
.asset-drawer:last-child {
    border-right:none!important;
}
.asset-tab-icon{
  padding-right:6px;

  &-3d{
    padding-bottom:8px;
    padding-right:8px;
  }
}
.svg-icon:active path{
  fill:red;

}
.asset-mail-icon{
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;

}
.nav-tabs{
  border-bottom:0px;
}
.nav-tabs .nav-link {
  border: 0px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-link.active{
  background:#fff;
  border-top:none ;
  border-right:none ;
  border-left:none ;
  border-bottom: none!important;
}

.nav-link.active:after{
  content: ""; 
  position: absolute;
  display: block;
  margin: 0 auto;
  left: 5%;
  width: 90%;
  bottom: 2px;
  border-bottom: 2px solid #E31B23;
}

.nav-link{
  position: relative;


  &--amf{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  &--rbs{

    &.active{
      border: 0px solid transparent;
      border-top:1px solid $cumin!important;
      border-left:1px solid $cumin!important;
      border-right:1px solid $cumin!important;
      border-radius:0;

      &:after{
        content: ""; 
        display: block;
        width: 50%;
        left: 25%;
        padding-top: 10px;
        border-bottom: 1px solid #CC521E;

      }
    }
  }
}

  a {
    &.active {
      i { color:#E31B23;  ; }
    }
}
.asset-row{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  &--amf{
    margin-left:20px!important;
    margin-bottom:20px;
  }
  &--rbs{
    margin-bottom:1rem;
    max-width: 1200px;
  }
}
.asset-box{
  position: relative;
  justify-content: center;
  align-items: center;

  &--amf{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    flex: 0 0 285px;
    height: 233px;
    margin: 31px 20px 0px 0px;
  }
  &--rbs{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    flex: 0 0 220px;
    height: 220px;
    width: 220px;
    margin: 26px 20px 0px 0px;
  }
}
.asset-box:hover{
  text-decoration: underline;
  text-decoration-color: white;
}
.asset-box:active{
  text-decoration: underline;
  text-decoration-color: white;
}
.asset-img{
  width:100%;
  background: url('https://picsum.photos/id/477/200/200') center/cover;

  &--amf{
    height:190px;
  }
  &--rbs{
    height:160px;
  }
}
.asset-img:hover{
  text-decoration: underline;
  text-decoration-color: white;
}
.asset-img:active{
  text-decoration: underline;
  text-decoration-color: white;
}
.asset-img-text{
  width:100%;
  display:flex;
  justify-content:space-between;

  &--amf{
    height:43px;
    background-color: #333333;
    align-items:space-between;
  }
  &--rbs{
    height:60px;
    background-color: $bridal-heath;
    align-items:center;
  }
}
.asset-img-text:hover{
  text-decoration: underline;
  text-decoration-color: white;
}
.asset-img-text:active{
  text-decoration: underline;
  text-decoration-color: white;
}
.asset-text{	
  line-height: 18px;
  margin-top:2px;
  margin-left:6px;

  &--amf{
    color: #FFFFFF;	
    font-family:$font-merriweather;	
    font-size: 13.5px;
  }
  &--rbs{
    color: $metallic-bronze;	
    font-family:$font-josefin;	
    font-size: 16px;
    font-weight:$regular;
  }
}
.asset-text:hover{
  text-decoration: underline;
  text-decoration-color: white;
}
.asset-text:active{
  text-decoration: underline;
  text-decoration-color: white;
}
.asset-reminder-note{
  font-weight:bold;
  text-transform:uppercase;
}
.asset-reminder{
  margin-left:25px;
  margin-top:20px;
}
.fa-envelope{
  color:white;
  margin-right:4px;
}
.hidden { 
  display:none;
}
.asset-file-extension {
  position: absolute;
  top: 11px;
  right: 11px;
  background-color:$white;
  box-sizing: border-box;	
  height: 25px;	
  width: 60px;
  font-family: $font-helvetica;
  text-align: center;
  text-transform: uppercase;

  &--amf {
    font-family: "montserrat-regular", sans-serif;
    color:$black;
    border: 1px solid $black;	
    border-radius: 0;
  }

  &--rbs {
    color:$metallic-bronze;
    border: 1px solid $metallic-bronze;	
    border-radius: 16.5px;
  }
}