#primary-nav {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;

    &-toggle {
        align-items: center;
        color: #000;
        display: flex;
        font-family: $font-montserrat-bold;
        font-size: 14px;
        height: 100%;
        justify-content: center;
        width: 100%;
        z-index: 3;

        &-icon {
            align-items: center;
            display: flex;
            justify-content: center;
            line-height: 1em;

            img {
                margin-right: 9px;
            }
        }
    }

    &-menu {
        background-color: white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
        left: 0;
        position: absolute;
        top: 91px;
        width: 339px;
        z-index: 3;

        &-home {
            background-color: #333;
            color: white;
            display: block;
            font-family: $font-montserrat-bold;
            margin-bottom: 24px;
            padding: 10px 0 10px 32px;
        }

        ul {
            list-style-type: none;
            margin: 0 0 40px;
            padding: 0 0 0 32px;
            width: 201px;

            li {
                font-family: $font-merriweather;
                font-size: 14px;
                margin-bottom: 19px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: inherit;
                }
            }

            .header {
                border-bottom: 1px solid #E31B23;
                font-family: $font-merriweather-bold;
                font-size: 18px;
                padding-bottom: 6px;
            }
        }

        &-search {
            background-color: #333;
            color: white;
            display: inline-block;
            font-family: $font-montserrat-bold;
            margin: 0 0 12px 32px;
            padding: 10px 18px;
        }
        .sync-btn{
            width:268px;
            font-family: "montserrat-bold", sans-serif;
            margin: 0 0 12px 32px;
            


            &:hover{
                text-decoration: underline;
                background-color:#fff;
                color:#333;
            }

            &-icon{
                padding-right:10px;
            }
    
        }
        .download-btn-icon{
            padding-right:10px;
            padding-bottom:12px;
        }
        .download-btn-icon:hover{
            fill:#fff;
            color:#fff;
        }
        
    }

    &-overlay {
        background-color: rgba(255, 255, 255, 0.5);
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        top: 0;
        z-index: 2;
    }
    
}
#rbs-primary-nav {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;

    &-toggle {
        align-items: center;
        color: #000;
        display: flex;
        font-family: $font-montserrat-bold;
        font-size: 14px;
        height: 100%;
        justify-content: center;
        width: 100%;
        z-index: 3;

        &-icon {
            align-items: center;
            display: flex;
            justify-content: center;
            line-height: 1em;

            img {
                margin-right: 40px;
            }
        }
    }

    &-menu {
        background-color: white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
        left: 0;
        position: absolute;
        top: 86px;
        width: 339px;
        z-index: 3;

        &-home {
            background-color: $cumin;
            color: white;
            display: block;
            font-family: $font-lato;
            margin-bottom: 24px;
            padding: 10px 0 10px 32px;
        }

        ul {
            list-style-type: none;
            margin: 0 0 40px;
            padding: 0 0 0 32px;
            width: 201px;

            li {
                font-family: $font-josefin;
                font-size: 16px;
                color:$metallic-bronze;
                margin-bottom: 19px;
                width:200px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: inherit;
                }
            }

            .header {
                border-bottom: 1px solid $metallic-bronze;
                font-family: $font-lato;
                color:$metallic-bronze;
                text-transform:uppercase;
                font-size: 18px;
                padding-bottom: 6px;
                width:200px;
            }
        }

        &-search {
            background-color: #333;
            color: white;
            display: inline-block;
            font-family: $font-montserrat-bold;
            margin: 0 0 12px 32px;
            padding: 10px 18px;
        }
        .sync-btn{
            width:268px;
            font-family: "montserrat-bold", sans-serif;
            margin: 0 0 12px 32px;
            


            &:hover{
                text-decoration: underline;
                background-color:#fff;
                color:#333;
            }

            &-icon{
                padding-right:10px;
            }
    
        }
        .download-btn-icon{
            padding-right:10px;
            padding-bottom:12px;
        }
        .download-btn-icon:hover{
            fill:#fff;
            color:#fff;
        }
        
    }

    &-overlay {
        background-color: rgba(255, 255, 255, 0.5);
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        top: 0;
        z-index: 2;
    }
    
}