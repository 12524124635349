.contact-container{
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .contact-backbutton{
    margin-left: 15%;
    margin-top: 5%;
}
  .contact-row-override{
    max-width:427px;
  }
  .contact-title{
    line-height:28px;
    font-size: 24px;
    font-weight:400;

    &--amf{
      font-family: $font-saira;
      color:#000;

      &:after{
        content: ""; 
        display: block;
        margin: 0 auto;
        width: 100%;
        border-bottom: 1px solid #979797;
      }
    }
    &--rbs{
    font-family: $font-lato;
    color:$metallic-bronze;
    text-transform: none;

    &:after{
      content: ""; 
      display: block;
      margin-top:13px;
      width: 100%;
      border-bottom: 1px solid $metallic-bronze;;
    }

    }
  }
  
  .contact-description{

    &--amf{
      font-family:$font-merriweather;
      font-size:16px;
      font-weight:300;
    }
    &--rbs{
      font-family:$font-helvetica;
      font-size:16px;
      font-weight:400;
      color:$metallic-bronze;

    }
  }
  .italicize{
    font-style:italic;
  }
  .bold{
    font-family:$font-merriweather-bold;
  }
  
  .contact-form{
    max-width:405px;
    font-family:$font-merriweather;
  }
  .form-group{
    font-size:16px;

    &--amf{
      font-family:$font-merriweather;
      font-weight:300;
    }
    &--rbs{
      font-family:$font-helvetica;
      color:$metallic-bronze;
      font-weight:$regular;

      label{
        font-family:$font-helvetica;
        color:$metallic-bronze;
      }
    }
  }
  .mail-icon{
    margin-right:15px;
  }
  .btn-primary{
    width:197px;
    height:50px;
    margin-top:25px;
    margin-bottom:35px;
    border-color:transparent;
    text-decoration: none;
  }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #333333;
    border-color:transparent;
  }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    border-color:transparent;

  }

  .fa-lg{
    color:black;
  }
  .login-container{
    height:100%;
    width: 100%;
    display:flex;
    align-items:center;
    justify-items:center;

  }
.osk-mask {
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 2;
}
.osk-content {
  position: absolute;
  z-index: 3;
  width: 100%;
  top: 0;
  bottom: 0;
  padding-top: 363px;
  .contact-row {
    margin-left: 37%;
    background-color: white;
  }
  .form-control {
    display: inline-block;
    width: calc(100% - 75px);
    margin-right: 5px;
  }
  button {
    width: 70px;
    margin-top: 0;
    margin-bottom: 5px;
    height: 37px;
    padding-top: 6px;
  }
}
.osk-keyboard {
  max-width: 1000px;
  margin-left: auto;
  margin-right:auto;
}
.inner-width-override {
  display: flex;
  justify-content: center;
  align-items: center;
}