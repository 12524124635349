.page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &--rbs{

        h1{
            font-family:$font-lato;
            color:$metallic-bronze;
            font-size:30px;
            font-weight:$weight-black;
            text-transform: none;

        }
        h2{
            font-family: $font-josefin;
            font-size: 18px;
            margin-top:1.5rem;
            color:$metallic-bronze;
            text-transform: none;
            margin-bottom: 1.2rem;

        }
    }
}

.assets-menu {
    position: relative;

    &.active {
        .fa {
            transform: rotateZ(90deg);
        }
    }

    &-inner {
        background-color: $white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
        display: none;
        margin-top: 9px;
        position: absolute;
        right: 0;
        width: 210px;
        z-index: 1;

        .active & {
            display: block;
        }
        
        &-text{
            font-weight:400;

            &--amf{
                font-family:$font-montserrat;
                text-transform:uppercase;
                font-size:14px;
            }
            &--rbs{
                font-family:$font-josefin;
                text-transform:none;
                color:$metallic-bronze;
                font-size:16px;

                a{
                    color:$metallic-bronze;
                    font-family: $font-josefin;

                    &:hover{
                        color:$metallic-bronze; 
                    }

                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                border-bottom: 1px solid $alto;

                a {
                    display: block;
                    padding: 16px 19px;

                    img {
                        margin-right: 14px;
                    }
                }
            }
        }
    }
}