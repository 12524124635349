html {
    background-color: $body-bg;
}

body {
    overflow-x: hidden;
    max-width: 100%;
    height:100%;
}

.app-container--rbs{
    min-height: 100vh;
    position: relative;
    display: block;
    overflow: visible;
    padding-bottom: 130px;
}

a {
    color: #000;
}

a:hover {
    color: $link-color;
    text-decoration:underline;
}

.backbutton {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 25px;

    &--amf{
        font-family: $font-montserrat;
    }
    &--rbs{
        font-family: $font-helvetica;
        color:$metallic-bronze;
        font-size: 16px;

        &:hover{
            color:$metallic-bronze;
        }
    }
}
.all-photos-button{

    &--rbs{
        color:$metallic-bronze!important;
        font-family: $font-helvetica;
        cursor: pointer;
        font-size: 16px;
        margin: 16px 0 19px 0;
        

    }
}
.all-photos-button-icon{

    &--rbs{
        color:$metallic-bronze;
        margin-bottom:4px;
        padding-right: 4px;

    }
}

.back-button-icon{
    margin-bottom:4px;

    &--amf{
        padding-left:15px;
    }

    &--rbs{
        color:$metallic-bronze;
    }
}
h5 {
    font-family: $font-merriweather-bold;
}

h6 {
    font-family: $font-merriweather;
    //padding-bottom: 1.5em;
    font-weight: 300;
    line-height: 20px !important;
}

i {
    //color: $alizarin-crimson;
    padding-left: 5px;
}
.asset-photos-row{
    width:100%;
    margin-top: 1.3125rem;
}
.aseets-photos-row-inner{
    margin-left: 3px;
}
.asset-photos-box{
   margin-right:25px;
}

.product-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &-inner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &--rbs{
            max-width: 1200px;
            margin-top:54px;
        }
    }

    &-container{
        padding:1rem 0;
        width:100%;
        height:100%;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;

        &::after{
            content: "";
            flex:0 0 15px;   
        }

    }

    &-text {
        color: white;
        font-weight: 700;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
    }

    &-item {
        flex: 0 0 17rem;
        height: 17rem;
        margin: 15px 15px 15px 0;
        width: 17rem;
        display: flex;
        margin-left: auto;
        margin-right: auto;

        &--amf{
            background: url('https://picsum.photos/id/477/200/200') center/cover;
        }
        &--rbs{
            border-radius:10px;
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.5);
        }

        &-content {
           
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &--amf{
                background: rgba(0, 0, 0, 0.3);
            }
            &--rbs{
                background: none;
            }


            &-inner {
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                &--amf{
                    border: 1px solid white;
                    height: 80%;
                    width: 80%;
                }
                &--rbs{
                    
                }
    
                &-margin{
                    width:90%;
                    
                }

             }
        }
    }
}
.product-row-item--rbs:nth-child(n+6) {
        margin-top: 0;
}

.product-row-item--rbs:nth-child(5) {
        margin-right: 0;
}

.product-row-item-content-inner:hover{
    text-decoration:underline;
    text-decoration-color: #fff;
}
.product-row-item-content-inner:active{
    text-decoration:underline;
    text-decoration-color: #fff;
}
.product-row-item-content:hover{
    text-decoration:underline;
    text-decoration-color: #fff;
}
.product-row-item-content:active{
    text-decoration:underline;
    text-decoration-color: #fff;
}
.product-row-item {
    display: flex;

    &--amf{
        flex: 0 0 11rem;
        height: 11rem;
        width: 11rem;
        margin: 54px 15px 15px 0;
    }
    &--rbs{
        margin: 0px 25px 25px 0;
        flex: 0 0 13.75rem;
        height: 13.75rem;
        width: 13.75rem;
    }
}

.product-row-item:last-child {
    margin-right: 0;
}
.product-row-image{

&--rbs{
height:120px;
width:180px;
margin-top:1.31rem;
}
}
.product-row-item-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    &--amf{
        background: rgba(0, 0, 0, 0.6);
        align-items: center;
    }
    &--rbs{
        flex-wrap:wrap;
        align-items: flex-start;
    }
}

.product-content2 {
    border: 1px solid white;
    height: 83%;
    width: 83%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}


.product-row-text {
    font-weight: 700;
    text-align: center;

    &--amf{
    color: white;
    font-family: $font-saira;
    font-size: 22px;
    text-transform: uppercase;
    }
    &--rbs{
        color: $metallic-bronze;
        font-family: $font-lato;
        font-size: 21px;
        text-transform: none;

    }

}
.product-row-text:hover{
    text-decoration: underline;
    text-decoration-color: white;
}
.product-row-text:active{
    text-decoration: underline;
    text-decoration-color: white;
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    max-width: 1417px;
}

.fa.fa-navicon {
    color: $alizarin-crimson;
}

.navbar-brand-centered {
    position: absolute;
    left: 45%;
    display: block;
    width: 160px;
    text-align: center;

}

.btn {

    &--amf {
        font-family: $font-montserrat-bold;
        text-transform: uppercase;
    }
    &--rbs {
        font-family: $font-josefin;
        font-weight: $bold;
        background-color:$cumin;
        color:$white;

        &:hover{
            background-color:$cumin;
            color:$white;
            border:1px solid $cumin
        }
    }
}
.rbs-search-icon {
    padding-right: 6px;
    margin-bottom: 3px;
}
.btn:focus, .btn:active, .btn:hover {
    outline: none !important;
    box-shadow: none !important;
  }

.btn.btn-square {
    border-radius: 0;
    width:280px;
    height:46px;
}
.download-btn{

    &--rbs{
        color:$cumin;
        background-color: $white;
        font-family: $font-helvetica;
        font-size: 16px;
        border: 1px solid $cumin;
        width:214px;
        height:46px;
        border-radius: 0%;

        &:hover{
            color:$cumin;
        }

        img{
            width:26px;
            height:25px;
            float:left;
        }
        
    }
}

.buttons-below-images-row1 {
    padding: 1rem 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &--amf{
    justify-content: center;
    }

    &--rbs{
        background-color:$cumin;
        justify-content: center;
        margin-top:2%;
    }

}

.buttons-below-images-row2 {
    display: flex;
    flex-wrap: wrap;
    

    &--amf{
        width: 935px;
        justify-content: space-between;
    }
    &--rbs{
        justify-content:center;
        max-width:1200px;
    }
}


.buttons-below-images-box {
    align-items: center;

    &--amf{
    border-bottom: 2px solid #CCCCCC;
    border-top: 2px solid #CCCCCC;
    display: flex;
    width: 120px;
    justify-content: center;

    }
    &--rbs{
        border:none;
        flex: 0 32%;
        height: 60px;
        display:flex;

        a{
            margin-left:25%;
        }

        a:hover{
            color:#fff;
        }

    }
}

.buttons-below-images-line {
    text-transform: uppercase;
    padding-top: 9px;
    padding-bottom: 4px;

    &--amf{
    font-family: $font-montserrat-bold;
    font-size: 16px;
    }

    &--rbs{
        color:$white;
        font-family: $font-josefin;
        font-size: 18px;
        font-weight:$regular;
        text-decoration-color: $white;
    }

}
.fa-caret-right{
    &--amf{
    color:$alizarin-crimson;
    }
    &--rbs{
        color:$white;
    }

}

/*
.buttons-below-images-row {
    margin-top: $pad-top;
    width:100%;
}

.buttons-below-images-one-line {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1em;
    border-bottom: 2pt solid #CCCCCC;
    padding-bottom: 1.5em;
    border-top: 2pt solid #CCCCCC;
    padding-top: 1.1em;
    border-spacing: 300em;
}

.buttons-below-images-two-lines {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
    text-transform: uppercase;
    line-height: .5;
    border-bottom: 2pt solid #CCCCCC;
    padding-bottom: 15.1px;
    border-top: 2pt solid #CCCCCC;
    padding-top: 1.1em;
    border-spacing: 300em;
}
*/
.center-question-row {
    height: 204px;
    &--amf {
        background-color: #333333;
        margin-top: 50px;
    }
    &--rbs {
        background-color:$white;
        margin-top: 0px;
    }
}


.center-question {
    font-size: 18px;
    line-height: 23px;
    margin-top: 3.3em;
    &--amf {
        color: $body-bg;
        font-family: $font-merriweather;
    }
    &--rbs{
        color: $metallic-bronze;
        font-family: $font-josefin;
    }
}

.unit-equipment-button {
    padding-top: -40em;
    margin-bottom: 4em;
    font-size: 16px;
    
}

.footer-menu-main-row {

    &--amf{
        margin-top: 51px;
        border: 1px solid #CCCCCC;
        h6 {
            padding-bottom: 1.5em;
        }
    }
    &--rbs{
        margin-top: 0px;
        border: none;
        position: absolute;
        width: 103%;
        bottom: 0;
        left:0;
        right:0;
    }
}

.footer-menu-nested-row {
    padding-left: 1.5em;

    &--amf{
        margin-top: 1.5em;
    }
    &--rbs{

    }
}

.footer-menu-col {

    &--amf{
        background-color: $concrete;
    }
    &--rbs{
        background-color: $bridal-heath;
    }
}

.footer-menu-title{

    &--amf{

        &:after{
            content: "";
            display: block;
            width: 85%;
            padding-top: 1px;
            border-bottom: 1px solid #E85B61;
        }
    }
    &--rbs{
    color: $metallic-bronze;
    font-family:$font-helvetica;
    font-size: 14px;
    text-transform: uppercase;

    &:after{
        content: "";
        display: block;
        width: 85%;
        padding-top: 1px;
        border-bottom: 1px solid $metallic-bronze;
    }

    }
}
.service-footer-menu-title{
    color: $metallic-bronze;
    font-family:$font-helvetica;
    font-size: 14px;
    text-transform: uppercase;

    &:after{
        content: "";
        display: block;
        width: 91%;
        padding-top: 1px;
        border-bottom: 1px solid $metallic-bronze;
    }
}
.corporate-footer-menu-title{
    color: $metallic-bronze;
    font-family:$font-helvetica;
    font-size: 14px;
    text-transform: uppercase;

    &:after{
        content: "";
        display: block;
        width: 51%;
        padding-top: 1px;
        border-bottom: 1px solid $metallic-bronze;
    }
}

.footer-menu-items {

    &--amf{
        margin-top: 1.4em;
    }
    &--rbs{
        margin-top: 17.5px;
        color:$metallic-bronze;
        font-family: $font-helvetica;
        font-size: 14px;

        li{
            color:$metallic-bronze;
            font-family: $font-helvetica;
            font-size: 10px;

            a{
                color:$metallic-bronze;
                font-family: $font-helvetica;
                font-size: 10px;
    
            }
        }
    }
}
.service-footer-menu-items{
    margin-top: 17.5px;
    color:$metallic-bronze;
    font-family: $font-helvetica;
    font-size: 14px;

    li{
        color:$metallic-bronze;
        font-family: $font-helvetica;
        font-size: 14px;

        a{
            color:$metallic-bronze;
            font-family: $font-helvetica;
            font-size: 14px;

        }
    }
}
.corporate-footer-menu-items{
    margin-top: 17.5px;
    color:$metallic-bronze;
    font-family: $font-helvetica;
    font-size: 14px;

    li{
        color:$metallic-bronze;
        font-family: $font-helvetica;
        font-size: 14px;

        a{
            color:$metallic-bronze;
            font-family: $font-helvetica;
            font-size: 14px;

        }
    }
}

.footer-logo-and-link-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items:center;
}
.footer-logo-and-link-container2{
  height:80%;
  width: 100%;
  
}
.footer-logo-box{
  width:100%;
  height:129px;
  display:flex;
  align-items:center;
  justify-content:center;
}
.footer-logo{
  width:150px;
  height:102px;

  &--amf{
    width:150px;
    height:102px;
  }
  &--rbs{
    width:385px;
    height:110px;
  }
}
.footer-link{
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  margin-top:70px;
  margin-left:10px;
}
.fa-external-link{
  margin-left:4px;
  margin-top:12px;
}

//UNIT EQUIP PAGE
/*
.arr-right .breadcrumb-item+.breadcrumb-item::before {
    content: ">";
    vertical-align: top;
}

.related-equipment-title {
    border-bottom: 1px solid #CCCC;
}

.related-equipment-row {
    border: 1px solid black;
    padding: 5rem 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.related-equipment-box {
    background-color: #dfdfdf;
    border: 1px solid black;
    flex: 0 0 17rem;
    height: 17rem;
    margin: 0 44px 15px 0;
    width: 17rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
*/

@media only screen and (max-width: 1093px) {
    .unit-equipment-row1{
        padding-left:15px !important; 
        
    }
  }
  @media only screen and (max-width: 1198px) {
    // Apply nth-child directly to product-row-item with the --rbs modifier
    .product-row-item--rbs:nth-child(n+6) {
        margin-top: 54px;
    }

    .product-row-item--rbs:nth-child(5) {
        margin-right: 25px;
    }
}

@media (min-width: 544px) {
    .boxtext {
        font-size: 0px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .boxtext {
        font-size: 16px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1025px) {
    .product-row-container{
        width:70%;
    }
    .col-lg-9 {
        flex: 0 0 73.4% !important;
        max-width: 73.4% !important;
    }

    .col-lg-3 {
        flex: 0 0 26.6% !important;
        max-width: 26.6% !important;
    }
    
}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .boxtext {
        font-size: 28px;
    }
}

// MUST IMPORT THIS AT THE BOTTOM OF THE CUSTOM SASS FILE
// Path must be accurate to the node_modules folder within 
// the main project folder
// ///////////////////////////////////////////////////////
// Bootstrap Sass Imports

/* Small devices (landscape phones, 544px and up) */