//UNIT EQUIP PAGE

.arr-right .breadcrumb-item+.breadcrumb-item::before {
    content: ">";
    vertical-align: top;

    &--rbs{
        color:$metallic-bronze;
    }
}
.breadcrumb-item+ .breadcrumb-item::before{
    color:$metallic-bronze;
}
.breadcrumb-item{

    &--rbs{
        color:$metallic-bronze;
        .active{
            color:$metallic-bronze;
        }
        &::before{
            color:$metallic-bronze;
        }
    }
}

.breadcrumb {
    font-family: $font-montserrat;
    width: 100% !important;

    &--amf{
        font-family: $font-montserrat;
    }
    &--rbs{
        font-family: $font-helvetica;
        font-weight:400;
        font-size: 14px;
        background-color:$bridal-heath;
        color:$metallic-bronze;

        a{
            color:$metallic-bronze;
        }
    }

}

.open-frame-mixer-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.heading-and-asset-button-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.open-frame-mixer-text-column {
    flex: 0 0 44%;
    align-items: flex-start;
}
.open-frame-mixer-text{
    margin-right:1rem;

    &--rbs{

        p{
            color:$metallic-bronze;
            font-family: $font-helvetica;

        }
    }
}
.open-frame-mixer-image-column {
    background-color: #E5E5E5;
    flex: 0 0 56%;
    height: 354px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.open-frame-mixer-title {
    font-family: $font-saira-bold;
    color:#000;
    font-size: 40px;
    text-transform: uppercase;
}

.open-frame-mixer-pipe {
    color: red;
}

.open-frame-mixer-sub-title {
    font-family: $font-montserrat-bold;
    // font-weight: bold;
    color: #7F7F7F;
    font-size: 18px;
    line-height: 20px !important;
    text-transform: uppercase;
    line-height: 48px;
    margin-bottom:20px;

    &--rbs{
        display:none;
    }

}

.open-frame-mixer-description {
    list-style-type: none;
    list-style-position: outside;
    text-indent: -4px;
    font-size: 14px;
    padding-right: 20px;
    margin-top: 20px;
    font-family: $font-merriweather;
    line-height: 28px;
}

ul.open-frame-mixer-description li:before {
    content: "•";
    font-size: 10pt;
    position: relative;
    left: -30px;
}

.unit-equipment-box {
    height: 354px;
    width: 549px;
    margin-left: auto;
    margin-top: 10px;

}

.unit-equipment-image {
    background: url('https://picsum.photos/id/477/200/200') center/cover;
    height: 100%;
    width: 100%;
    background-color: white;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: contain;
    background-repeat: no-repeat;

}

.assets {
    height: 40px;
    width: 113px;
}


.all-products-row {
    padding: 1rem 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    &--rbs{

        a{
            color:$metallic-bronze;
        }
        &a{
            color:$metallic-bronze
        }
    }
}

.all-products-box {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin: 0 20px 15px 0;
    justify-content: center;
    align-items: center;

    &--amf{
        height: 106px;
        width: 106px;
    }
    &--rbs{
        height: 120px;
        width: 120px;
    }
}

.all-products-img {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &--amf{
        background: url('https://picsum.photos/id/477/200/200') center/cover;
    }
    &--rbs{
        background: url('https://picsum.photos/id/477/200/200') center/contain;
        background-repeat: no-repeat;
    }
}

.view-all-products {

    &--amf{
        width: 106px;
    }
    &--rbs{
        width: 133px;
    }
}

.view-all-products-text {
    text-transform: uppercase;
    line-height: 1.5em;
    padding-bottom: 1em;
    margin-left: 1em;
    padding-top: 2.1em;
    border-spacing: 300em;

    &--amf{
        font-size: 12px;
        border-bottom: 2pt solid #CCCCCC;
        border-top: 2pt solid #CCCCCC;
        font-family: $font-montserrat-bold;
        margin-top: 1.5em;
        padding-top: 1em;
        
        img {
            padding-left: 5px;
        }
    }
    &--rbs{
        font-size:18px;
        font-family: $font-josefin;
        color:$metallic-bronze;
        text-align: left;

        &:hover{
            
        }
    }
}

.related-equipment-title {
    margin-bottom: 10px;
    margin-top: auto;

    &--amf{
        font-family: $font-saira;
        font-weight:300;
        color: #4C4C4C;

        &:after{
            border-bottom: 1px solid #CCCC;
        }
    }
    &--rbs{
        font-family: $font-josefin;
        font-weight:$regular;
        color: $metallic-bronze;
        text-transform: uppercase;
        font-size: 18px;

        &:after{
            border-bottom: 1px solid $metallic-bronze;
        }
    }
}
.related-equipment-title:after{
content: "";
    display: block;
    width: 90%;
    padding-top: 4px;
}

.related-equipment-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.related-equipment-box {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin: 9px 9px 9px 9px;
    justify-content: center;
    align-items: center;

    &--amf{
        height: 210.12px;
        width: 200px;
    }
    &--rbs{
        height: 220px;
        width: 220px;

        & a{
            color:$metallic-bronze;
        }
        &:hover{
            color:$metallic-bronze;
        }
    }


}

.related-equipment-img {
    background: url('https://picsum.photos/id/477/200/200') center/cover;
    height: 65%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.related-equipment-textbox {
    height: 35%;
    width: 100%;
   // display:flex;
   // justify-content:center;
   // align-items:center;

   &--amf{
    background-color: $concrete;
   }
   &--rbs{
    background-color: $bridal-heath;
    display:flex;
    align-items:center;
   }
}

.related-equipment-text {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 12px;
    width: 99%;

    &--amf{
        font-family: $font-merriweather;
    }
    &--rbs{
        font-family: $font-josefin;
        color:$metallic-bronze;

        a{
            color:$metallic-bronze;
        }
    }    
}
.view-all-products-icons{
    margin-bottom:4px;
}