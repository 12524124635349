.category-model-page-title{
margin-top:auto;
font-weight: 700;
font-size:40px;

&--amf{
    font-family: $font-saira-bold;
    text-transform:uppercase;
    margin-bottom:23px;
}
&--rbs{
    font-family: $font-lato;
    color:$metallic-bronze;
    font-size:30px;
    text-transform:none;
    
}
}
.category-model-title{
margin-top:auto;

&--amf{
    font-family: $font-saira;
    color: #4C4C4C;
    font-weight: 300;
    margin-bottom:10px;
    text-transform:uppercase;

    &:after{
        content: "";
        display: block;
        width: 90%;
        padding-top: 4px;
        border-bottom: 1px solid #CCCC;
    }
}
&--rbs{
    color: $metallic-bronze;
    font-family: $font-josefin;
    font-size: 18px;
    margin-bottom:0px;
    margin-top:25px;
    text-transform: none;
    max-width: 1244px;

    &:after{
        content: "";
        display: block;
        width: 95%;
        padding-top: 4px;
        border-bottom: 1px solid $metallic-bronze;
    }
}
}

.category-model-row{
width: 100%;
margin-bottom:20px;
margin-left:1px;
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: flex-start;
}
.category-model-box{
box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
flex: 0 0 200px;
height: 205px;
margin: 15px 45px 15px 0px;
justify-content: center;
align-items: center;

}
.category-model-img{
background: url('https://picsum.photos/id/477/200/200') center/cover;
height:65%;
width:100%;
margin-left:auto;
margin-right:auto;
}
.category-model-textbox{
height:35%;
width:100%;
background-color:$concrete;
}
.category-model-text{
    font-weight: 300;
    font-size: 16px;
    padding-top: 10px;
    padding-left: 12px;
    width: 90%;
}